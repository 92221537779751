<template>
    <div class="examine-single-client">
        <div class="checking-makat" v-if="client_makat && !final_report">
            <div class="checking-line">
                <p>בודקת לקוח</p>
                <i class="el-icon-loading" v-if="!client_data"></i>
                <i style="color:var(--success)" class="material-icons" v-if="client_data">check_circle_outline</i>
            </div>
            
            <div class="checking-line">
                <p>בודקת מגוון</p>
                <i class="el-icon-loading" v-if="!assortment"></i>
                <i style="color:var(--success)" class="material-icons" v-if="assortment">check_circle_outline</i>
            </div>

            <div class="checking-line">
                <p>בודקת חשבוניות - עד 1000 חשבוניות</p>
                <i class="el-icon-loading" v-if="!client_invoices"></i>
                <i style="color:var(--success)" class="material-icons" v-if="client_invoices">check_circle_outline</i>
            </div>

            <!-- <div class="checking-line">
                <p>מחפשת לקוחות דומים</p>
                <i class="el-icon-loading" v-if="!similar_clients"></i>
                <i style="color:var(--success)" class="material-icons" v-if="similar_clients">check_circle_outline</i>
            </div> -->

            <div class="checking-line">
                <p>מייצרת דו"ח לקוח</p>
                <i class="el-icon-loading" v-if="!final_report"></i>
                <i style="color:var(--success)" class="material-icons" v-if="final_report">check_circle_outline</i>
            </div>
        </div>
        <div class="report" v-if="final_report">
            <div class="title">
                <p>לקוח</p>
            </div>
            <div class="report-line">
                <p>שם הלקוח:</p>
                <p>{{client_data.name}}</p>
                <p>({{client_data.makat}})</p>
            </div>
            <div class="report-line">
                <p>סוכן מייצג:</p>
                <p>{{client_data.agent_name}}</p>
                <p>({{client_data.agent}})</p>
            </div>
            <div class="report-line">
                <p>כתובת:</p>
                <p>{{client_data.adress}}</p>
                <p>{{client_data.city}}</p>
            </div>
            <div class="report-line">
                <p>אוביגו:</p>
                <p>{{client_data.obligo}}</p>
            </div>
            <div class="report-line">
                <p>תנאי תשלום:</p>
                <p>{{client_data.payment}}</p>
            </div>

            <div class="title">
                <p>מכירות - מוצרים</p>
            </div>
            <div class="report-line">
                <p style="color:var(--warning)">מגוון:</p>
                <p>{{client_data.merakez}}</p>
                <p>{{assortment.length}} פריטים פעילים</p>
            </div>
            <div class="report-line">
                <p style="color:var(--warning)">נרכשו מתוך המגוון:</p>
                <p>{{report.items_sold.length}} פריטים</p>
            </div>
            <div class="report-line">
                <p style="color:var(--danger)">לא נרכשו:</p>
                <p>{{report.items_not_sold.length}} פריטים</p>
            </div>
            <div class="result-table">
                <table id="table">
                    <tr>
                        <th>מקט</th>
                        <th>שם</th>
                        <th @click="sort('pcs', report.items_data)">יחידות</th>
                        <th>הכנסה</th>
                        <th>רווח</th>
                        <th>% רווח משוכלל</th>
                        <th>מס. הזמנות</th>
                        <th>תדירות</th>
                        <th>הז.אחרונה</th>
                    </tr>
                    <template v-for="item in report.items_data" :key="item.makat">
                        <tr>
                            <td>{{item.makat}}</td>
                            <td>{{item.name}}</td>
                            <td>{{item.total_pcs}}</td>
                            <td>{{item.total_income.toFixed(2)}}</td>
                            <td>{{item.profit.toFixed(2)}}</td>
                            <td>{{item.profit_percent.toFixed(2)}}</td>
                            <td>{{item.in_orders}}</td>
                            <td>{{(item.in_orders / client_invoices.length).toFixed(2)}}</td>
                            <td>{{item.last_purchase.toLocaleDateString('he')}}</td>
                        </tr>
                    </template>
                </table>
            </div>

            <div class="title" style="margin-top: 10px;">
                <p>מכירות - הזמנות</p>
            </div>
            <div class="report-line">
                <p style="color:var(--warning)">נמשכו:</p>
                <p>{{client_invoices.length}} הזמנות</p>
            </div>
            <div class="report-line">
                <p style="color:var(--warning)">תאריכי הזמנות:</p>
                <p>{{new Date(client_invoices[0].CURDATE).toLocaleDateString('he')}} - {{new Date(client_invoices[client_invoices.length - 1].CURDATE).toLocaleDateString('he')}}</p>
            </div>
        </div>

    </div>
</template>

<script>
import { onMounted, ref } from '@vue/runtime-core'
import Swal from 'sweetalert2'
import { projectFirestore, projectFunctions } from '../../firebase/config';
import { slide_pop_error } from '../../Methods/Msgs';
export default {
setup(){
    const client_makat = ref(null);
    const client_data = ref(null);
    const assortment = ref(null);
    const client_invoices = ref(null);
    const similar_clients = ref(null);
    const final_report = ref(null);
    const report = ref({assortment: null,})

    const ask_what_client = () => {
        client_data.value = null;
        assortment.value = null;
        client_invoices.value = null;
        similar_clients.value = null;
        final_report.value = null;
        report.value = {assortment: null,};

        Swal.fire({
            icon: 'question',
            title: 'בחר לקוח',
            text: 'הכנס מספר לקוח בבקשה',
            input: 'text',
            confirmButtonText: 'המשך',
            cancelButtonText: 'ביטל',
            showCancelButton: true
        }).then(async res => {
            if(res.isConfirmed && res.value.length >= 4){
                client_makat.value = res.value;
                init_ai(); 
            }
        })
    }

    const init_ai = async () => {
        //fetching ALL data:
        // client profile:
        const data = await projectFirestore.collection('Clients').doc(client_makat.value).get();
        if(!data.exists){
            slide_pop_error("מספר לקוח לא נמצא במערכת!");
            ask_what_client();
            return
        }
        else{
            client_data.value = data.data();
        }

        //client assortment:
        let target_assortment = client_data.value.merakez
        const assortment_data = await projectFirestore.collection("Assortments").doc(target_assortment).get()
        const active_makats =  await projectFirestore.collection("Utils").doc("Products").get().then(doc => {
            return doc.data().makats
        })
        if(!assortment_data.exists){
            slide_pop_error("מגוון לקוח לא נמצא במערכת!");
            ask_what_client();
            return
        }
        else{
            let assortment_data_items = assortment_data.data().items
            let final_assortment = [];
            for(let i = 0; i < assortment_data_items.length; i++){
                let makat = assortment_data_items[i]
                let inx = active_makats.findIndex(item => {
                    return item == makat
                })
                if(inx != -1){
                    final_assortment.push(makat)
                }
            }
            assortment.value = final_assortment;
            report.value.assortment = final_assortment;
        }

        //Client invoices:
        const invoices = await projectFunctions.httpsCallable("get_client_orders", {timeout: 540000})({client: client_data.value.makat})
        if(invoices && invoices.data){
            client_invoices.value = invoices.data
            client_invoices.value = client_invoices.value.sort((invoiceA, invoiceB) => {
                return new Date(invoiceA.CURDATE) - new Date(invoiceB.CURDATE)
            })
            report.value.first_order_date = new Date(client_invoices.value[0].CURDATE)
            report.value.last_order_date = new Date(client_invoices.value[client_invoices.value.length - 1].CURDATE)
            client_data.value.agent_name = client_invoices.value[0].AGENTNAME;
        }else{
            await Swal.fire({
                icon: 'info',
                title: 'חשבוניות',
                text: 'לא נמצאו חשבוניות לקוח, לא אוכל להשלים את הניתוח.'
            })
            ask_what_client();
            return
        }

        await do_report();
    }

    const do_report = async () => {
        await check_client_assortment_sales();
        await check_client_assortment_not_sold();
        console.log(report.value);
        console.log(client_invoices.value);
        final_report.value = true;
    }

    const check_client_assortment_sales = async () => {
        console.log('Checking what DID sell.');
        let sold = [];
        let sold_data = [];
        for(let i = 0; i< client_invoices.value.length; i++){
            let invoice = client_invoices.value[i]
            if(invoice.ORDERITEMS_SUBFORM && invoice.ORDERITEMS_SUBFORM.length > 0){
                for(let j = 0; j < invoice.ORDERITEMS_SUBFORM.length; j++){
                    let item = invoice.ORDERITEMS_SUBFORM[j]
                    let inx = sold.findIndex(_item => {
                        return _item == item.PARTNAME
                    })
                    if(inx == -1 && assortment.value.includes(item.PARTNAME)){
                        sold.push(item.PARTNAME)
                        sold_data.push({
                            makat: item.PARTNAME,
                            total_income: item.QPRICE,
                            total_pcs: item.TQUANT,
                            profit: item.QPROFIT,
                            name: item.PDES,
                            profit_percent: item.QPROFIT / item.QPRICE * 100,
                            in_orders: 1,
                            last_purchase: new Date(item.DUEDATE)
                        })
                    }
                    if(inx != -1){
                        sold_data[inx].total_income += item.QPRICE;
                        sold_data[inx].total_pcs += item.TQUANT;
                        sold_data[inx].profit += item.QPROFIT;
                        sold_data[inx].profit_percent = sold_data[inx].profit / sold_data[inx].total_income * 100;
                        sold_data[inx].in_orders += 1;
                        if(new Date(item.DUEDATE) > sold_data[inx].last_purchase){
                            sold_data[inx].last_purchase = new Date(item.DUEDATE);
                        }
                    }   
                }
            }
        }
        sold= sold.sort((aa, bb) => aa - bb)
        report.value.items_sold = sold;
        report.value.items_data = sold_data;
        return true
    }

    const check_client_assortment_not_sold = async () => {
        console.log('Checking what DID NOT sell.');
        var not_sold = report.value.assortment.filter(makat => !report.value.items_sold.includes(makat))
        report.value.items_not_sold = not_sold;
        return true
    }

    const sort = (type, array) => {
        switch (type) {
            case 'pcs':
                if(array[0].total_pcs > array[array.length - 1].total_pcs){
                    array = array.sort((itA,itB) => itA.total_pcs - itB.total_pcs)
                }else{
                    array = array.sort((itA,itB) => itB.total_pcs - itA.total_pcs)
                }
                break;
        
            default:
                break;
        }

    }

    onMounted(() => {
        ask_what_client();
    })

    return{
        client_makat, assortment, client_data, client_invoices, similar_clients, final_report, report, sort
    }
}
}
</script>

<style scoped>
.checking-makat{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.checking-line{
    flex-shrink: 0;
    width: 370px;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: var(--secondary);
    padding: 5px;
    font-size: 18px;
    border-radius: 5px;
    margin-bottom: 10px;
    justify-content: space-between;
}
.report{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.title{
    flex-shrink: 0;
    width: 100%;
    height: 30px;
    background: var(--success);
    color: white;
    text-shadow: 0 0 3px black;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
#table {
    border-collapse: collapse;
    width: 100%;
}
#table td, #table th {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
  color: var(--main);
}
#table tr:nth-child(even){background-color: #f2f2f2;}
#table tr:nth-child(odd){background-color: #e7e6e6;}
#table tr:hover {background-color: #ddd;}
#table td:last-child{
    text-align: center;
    color: var(--secondary);
}
#table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: var(--purple);
  color: white;
  position: sticky;
  top: 0;
}
.result-table{
    margin-top: 10px;
    width: 100%;
    height: 450px;
    overflow-y: auto;
}
.report-line{
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 24px;
    align-items: center;
    margin-bottom: 5px;
    font-size: 18px;
}
.report-line p {
    margin-left: 25px;
}
.report-line :first-child {
    color: var(--yellow);
    font-weight: 400;
    width: 20%;
}
.report-line :nth-child(2) {
    width: 30%;
}
.report-line :nth-child(3) {
    width: 30%;
}
</style>